import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'link/:linkType',
          element: <Link />
        },
        {
          path: 'signup',
          children: [
            {
              element: (
                <GuestGuard>
                  <Register />
                </GuestGuard>
              )
            },
            { path: 'form', element: <SignUpForm /> },
            {
              path: 'xero',
              element: (
                <GuestGuard>
                  <RegisterXero />
                </GuestGuard>
              )
            }
          ]
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> }
      ]
    },
    { path: 'setup-step/:stepName', element: <SetUpStepper /> },
    // Auth Errors
    {
      path: 'error',
      children: [
        { path: 'non-user', element: <ErrorNotUser /> },
        { path: 'non-admin', element: <ErrorNotAdmin /> },
        { path: 'card-declined', element: <ErrorCardDeclined /> },
        { path: 'clean-firebase-user-failed', element: <ErrorCleanFirebaseUser /> },
        { path: 'link-failure', element: <ErrorLinkFailure /> },
        { path: 'link-required', element: <ErrorLinkRequired /> },
        {
          path: 'domain-existed',
          element: (
            <GuestGuard>
              <ErrorDomainExisted />
            </GuestGuard>
          )
        },
        {
          path: 'domain-generic',
          element: (
            <GuestGuard>
              <ErrorDomainGeneric />
            </GuestGuard>
          )
        }
      ]
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/people" replace /> },
        {
          path: 'calllog',
          element: <ConversationList />
        },
        {
          path: 'reporting',
          children: [
            { path: 'dashboard', element: <ReportDashboard /> },
            { path: 'groupreports', element: <RoleReports /> },
            { path: 'groupreports/:roleId', element: <RoleReportDetail /> },
            { path: 'peoplereports', element: <UserReports /> },
            { path: 'peoplereports/:userId', element: <UserReportDetail /> }
          ]
        },
        {
          path: 'groups',
          children: [
            { path: '', element: <RoleList /> },
            { path: 'groupslist', element: <RoleList /> },
            { path: 'createnewgroup', element: <CreateNewRole /> },
            { path: 'profile/:roleId', element: <RoleProfile /> }
          ]
        },
        {
          path: 'rules',
          children: [
            { path: '', element: <RoutingsList /> },
            { path: 'ruleslist', element: <RoutingsList /> },
            { path: 'createnewrule', element: <CreateNewRoute /> },
            { path: 'profile/:routingId', element: <RoutingProfile /> }
          ]
        },
        {
          path: 'integrations',
          children: [
            { path: '', element: <Integrations /> },
            { path: 'hubspot/redirect', element: <Integrations /> },
            { path: 'xero/redirect', element: <Integrations /> },
            { path: 'xpm/redirect', element: <Integrations /> }
          ]
        },
        {
          path: 'settings',
          children: [
            { path: '', element: <AccountView /> },
            { path: 'account', element: <AccountView /> },
            { path: 'numbers', element: <NumbersList /> },
            { path: 'createnewnumber', element: <CreateNewNumber /> },
            { path: 'integrations', element: <Navigate to="/integrations" replace /> },
            { path: 'billing', element: <Billings /> }
          ]
        },
        {
          path: 'people',
          children: [
            { path: '', element: <UserList /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'profile/:userId', element: <UserProfile /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Link = Loadable(lazy(() => import('../pages/authentication/LinkPage')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterXero = Loadable(lazy(() => import('../pages/authentication/RegisterXero')));
const ErrorNotUser = Loadable(lazy(() => import('../pages/authentication/ErrorNotUser')));
const ErrorCleanFirebaseUser = Loadable(lazy(() => import('../pages/authentication/ErrorCleanFirebaseUser')));
const ErrorNotAdmin = Loadable(lazy(() => import('../pages/authentication/ErrorNotAdmin')));
const ErrorCardDeclined = Loadable(lazy(() => import('../pages/authentication/ErrorCardDeclined')));
const ErrorDomainExisted = Loadable(lazy(() => import('../pages/authentication/ErrorDomainExisted')));
const ErrorDomainGeneric = Loadable(lazy(() => import('../pages/authentication/ErrorDomainGeneric')));
const ErrorLinkFailure = Loadable(lazy(() => import('../pages/authentication/ErrorLinkFailure')));
const ErrorLinkRequired = Loadable(lazy(() => import('../pages/authentication/ErrorLinkRequired')));

const SignUpForm = Loadable(lazy(() => import('../pages/authentication/SignUpForm')));
// Setup stepper
const SetUpStepper = Loadable(lazy(() => import('../pages/SetupSteppers')));
// Dashboard
// const ConversationList = Loadable(lazy(() => import('../pages/dashboard/calls/MasterCallLogContainer')));
const ConversationList = Loadable(lazy(() => import('../pages/dashboard/ConversationList')));
const UserReports = Loadable(lazy(() => import('../pages/dashboard/UserReports')));
const RoleReportDetail = Loadable(lazy(() => import('../pages/dashboard/RoleReportDetail')));
const UserReportDetail = Loadable(lazy(() => import('../pages/dashboard/UserReportDetail')));
const RoutingsList = Loadable(lazy(() => import('../pages/dashboard/RoutingsList')));
const RoleReports = Loadable(lazy(() => import('../pages/dashboard/RoleReports')));
const ReportDashboard = Loadable(lazy(() => import('../pages/dashboard/ReportDashboard')));
const RoleList = Loadable(lazy(() => import('../pages/dashboard/RoleList')));
const NumbersList = Loadable(lazy(() => import('../pages/dashboard/NumbersList')));
const CreateNewNumber = Loadable(lazy(() => import('../pages/dashboard/CreateNewNumber')));
const Integrations = Loadable(lazy(() => import('../pages/dashboard/Integrations')));
const Billings = Loadable(lazy(() => import('../pages/dashboard/Billings')));
const CreateNewRoute = Loadable(lazy(() => import('../pages/dashboard/CreateNewRoute')));
const CreateNewRole = Loadable(lazy(() => import('../pages/dashboard/CreateNewRole')));
const RoleProfile = Loadable(lazy(() => import('../pages/dashboard/RoleProfile')));
const RoutingProfile = Loadable(lazy(() => import('../pages/dashboard/RoutingProfile')));
const AccountView = Loadable(lazy(() => import('../pages/dashboard/AccountView')));

const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
